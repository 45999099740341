import 'templates/App.css';
import Homepage from "./components/HomePage/Homepage";

function App() {
    const state = {
        studentsIntro: [],
        teachersIntro: [],
        adminsIntro: [],
    }
    return (
        <div className="App">
            <Homepage studentsIntro={state.studentsIntro} teachersIntro={state.teachersIntro} adminsIntro={state.adminsIntro}/>
        </div>
    );
}

export default App;
