import React, { Component } from 'react'
import AOS from 'aos';
import "templates/HomePage/Home.css"
import TRUNK from 'vanta/dist/vanta.trunk.min';
import p5 from 'p5'

// delete showRecoveryForm and showLoginForm Refs

class Home extends Component {
    constructor(props) {
        super(props);

        this.vantaRef = React.createRef()
        AOS.init({
            duration: 800,
            easing: 'slide',
            once: false
        });
    }

    state = {
        formType: "login",// login, recovery
        username: "",
        password: "",
        email: "",

        loginUserValid: "form-control",
        loginPassValid: "form-control",
        recoverUserValid: "form-control",
        recoverEmailValid: "form-control",
    };

    componentWillReceiveProps() {
        AOS.refresh();
    }

    changeForm = () => {
        this.setState((state) => {
            if (state.formType === "login")
                return {formType: "recovery"};
            else
                return {formType: "login"}
        })
    };

    onChange = (e) => this.setState({
        ...this.state, [e.target.name]: e.target.value
    });

    componentDidMount() {
        this.vantaEffect = TRUNK({
            el: this.vantaRef.current,
            mouseControls: true,
            touchControls: true,
            gyroControls: false,
            minHeight: 200.00,
            minWidth: 200.00,
            scale: 1.00,
            scaleMobile: 1.00,
            color: 0xa7405f,
            backgroundColor: 0x000,
            p5: p5,
            spacing:3
        })
    }

    componentWillUnmount() {
        if (this.vantaEffect) this.vantaEffect.destroy()
    }


    render() {
        return (
            <div className="slide-1 home-bg" >
                <div className="container" ref={ this.vantaRef } style={{backgroundPosition: "25% 75%"}}>
                    <div className="row align-items-center" >
                        <div className="col-12">
                            <div className="row align-items-center">
                                <div className="col-lg-6 mb-4">
                                    <h1 data-aos="fade-up" data-aos-delay="100">Passionate About Creativity</h1>
                                    <p className="mb-4" data-aos="fade-up" data-aos-delay="200">We all have our own
                                        passions and talents. Curious about mine? Take a look around my website and
                                        you'll find out more about who I am and what I do. I hope you'll love my
                                        work!</p>
                                </div>
                                <div className="col-lg-5 ml-auto" data-aos="fade-up" data-aos-delay="500">


                                    <form className="form-box" autoComplete="off">
                                        <img src={ require('templates/Assets/images/snow1.png').default } alt="Image"
                                             className="img-fluid"/>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Home;
