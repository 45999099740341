import React, { Component } from 'react'
import AOS from 'aos';
import "templates/HomePage/Teachers.css"

class Teachers extends Component {
    constructor (props) {
        super(props);

        AOS.init({
            duration: 800,
            easing: 'slide',
            once: false
        });
    }

    componentWillReceiveProps (){
        AOS.refresh();
    }

    render() {
        return (
            <div className="container">

                <div className="row mb-5 justify-content-center">
                    <div className="col-lg-7 mb-5 text-center"  data-aos="fade-up" data-aos-delay="">
                        <h2 className="section-title">Gallery</h2>
                    </div>
                </div>

                <div className="row">

                    <div className="col-md-6 col-lg-4 mb-4" data-aos="fade-up" data-aos-delay="100">
                        <div className="teacher text-center">
                            <img src={require('templates/Assets/images/aNIMATION.png').default} alt="Image" className="img-fluid w-200 rounded ripple hover-overlay mx-auto mb-4"/>
                            <div className="py-2">
                                <h3 className="text-black">Animation</h3>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 col-lg-4 mb-4" data-aos="fade-up" data-aos-delay="200">
                        <div className="teacher text-center">
                            <img src={require('templates/Assets/images/sTORYBOARD.png').default} alt="Image" className="img-fluid w-200 rounded ripple hover-overlay mx-auto mb-4"/>
                            <div className="py-2">
                                <h3 className="text-black">Story Board</h3>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 col-lg-4 mb-4" data-aos="fade-up" data-aos-delay="300">
                        <div className="teacher text-center">
                            <img src={require('templates/Assets/images/pHOTOGRAPHY.png').default} alt="Image" className="img-fluid w-200 rounded ripple hover-overlay mx-auto mb-4"/>
                            <div className="py-2">
                                <h3 className="text-black">Photography</h3>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default Teachers;
