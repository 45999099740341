import React, { Component } from 'react'
import { MDBIcon } from "mdbreact"
import AOS from 'aos';
import "templates/HomePage/Programs.css"

class Programs extends Component {
    constructor (props) {
        super(props);

        AOS.init({
            duration: 800,
            easing: 'slide',
            once: false
        });
    }

    componentWillReceiveProps (){
        AOS.refresh();
    }

    render() {
        return (
            <div className="container">

                <div className="row mb-5 justify-content-center">
                    <div className="col-lg-7 text-center" data-aos="fade-up" data-aos-delay="">
                        <h2 className="section-title">About Me</h2>
                    </div>
                </div>

                <div className="row mb-5 align-items-center">
                    <div className="col-lg-7 mb-5" data-aos="fade-up" data-aos-delay="100">
                        <img src={require('templates/Assets/images/photo202110281800331.png').default} alt="Image" className="img-fluid"/>
                    </div>
                    <div className="col-lg-4 ml-auto" data-aos="fade-up" data-aos-delay="200">
                        <p className="mb-4">My name's Maryam Moezzi . I'm a digital arts student with a little experience.
They say that nothing worth doing is easy. Like every one of us, I’ve had my bumps in the road. But if you ask me, I’ll always tell you that I wouldn’t change a thing. Why? Because throughout my journey I’ve honed my skills and proven that my services get results in the real world.i want to share my journey with you through this platform.</p>

                    </div>
                </div>

            </div>
        )
    }
}

export default Programs;
