import React, { Component } from 'react'
import Sticky from 'react-stickynode';
// import ScrollspyNav from "react-scrollspy-nav"; // remove it
import { MDBIcon } from "mdbreact"
import { MDBCloseIcon } from "mdbreact"
import "templates/Header.css"

class Header extends Component {
    state = {
        shrink: false
    }

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll)
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll)
    }

    handleScroll = () => {
        var st = window.pageYOffset;
        if (st > 100 && this.state.shrink === false) {
            this.setState((state) => ({
                shrink: true
            }))
        } else if(st<100 && this.state.shrink === true) {
            this.setState((state) => ({
                shrink: false
            }))
        }
    }

    render(){

        let size = "non-shrink"
        if(this.state.shrink){
            size = "shrink"
        }

        let scrollIds = this.props.headerItems.map((item)=>{
            return item.id;
        })

        let itemsLength = this.props.headerItems.length;

        let limitedItems = this.props.headerItems.filter((item,index) => {
            return index != itemsLength-1;
        })
        let limitedItemsJSX = limitedItems.map((item) => {
            if(item.bookmark === true) {
                return <li key={item.id} onClick={item.onClick}><a href={"#" + item.id}>{item.name}</a></li>
            }
            else {
                return <li key={item.id} onClick={item.onClick}><a>{item.name}</a></li>
            }
        })

        let lastItem = this.props.headerItems[itemsLength - 1];
        if(lastItem.bookmark === true){
            lastItem =
                <li key={lastItem.id} className="cta" onClick={lastItem.onClick}><a href={"#"+lastItem.id}><span>{lastItem.name}</span></a></li>
        }
        else {
            lastItem =
                <li key={lastItem.id} className="cta" onClick={lastItem.onClick}><a><span>{lastItem.name}</span></a></li>
        }

        return(
            <div>
            {/*<ScrollspyNav scrollTargetIds={scrollIds} activeNavClass="active" scrollDuration="1000" headerBackground="true">*/}
                <div className="site-mobile-menu">
                    <div className="site-mobile-menu-header">
                        <div className="site-mobile-menu-close">
                            <span onClick={this.props.menuVisibility}><MDBCloseIcon/></span>
                        </div>
                    </div>
                    <div className="site-mobile-menu-body">
                        <ul className="site-nav-wrap">
                            {[...limitedItemsJSX]}
                        </ul>
                        <ul className="site-nav-wrap">
                            {lastItem}
                        </ul>
                    </div>
                </div>

                <Sticky activeClass="is-sticky">
                        <header className={`site-navbar py-4 ${size}`} role="banner"> {/* Scrollspy Offset */}
                            <div className="container-fluid">
                                <div className="d-flex align-items-center">
                                    <div className="site-logo mr-auto w-25">
                                        <img src={require('templates/Assets/images/frame.svg').default}/>
                                        <a href="#home-section">Maryam Moezzi</a>
                                    </div>
                                    <div className="mx-auto text-center">
                                        <nav className="site-navigation" role="navigation">
                                            <ul className="site-menu main-menu mx-auto d-none d-lg-block m-0 p-0 persian-text">
                                                {[...limitedItemsJSX]}
                                            </ul>
                                        </nav>
                                    </div>
                                    <div className="ml-auto w-25">
                                        <nav className="site-navigation position-relative text-right" role="navigation">
                                            <ul className="site-menu main-menu site-menu-dark mr-auto d-none d-lg-block m-0 p-0">
                                                {lastItem}
                                            </ul>
                                        </nav>
                                        <a className="d-inline-block d-lg-none text-black float-right" onClick={this.props.menuVisibility}><span className="h3"><MDBIcon icon="bars" /></span></a>
                                    </div>
                                </div>
                            </div>
                        </header>
                </Sticky>
            {/*</ScrollspyNav>*/}
            </div>
        )
    }
}

export default Header;