import React, { Component } from 'react'
import AOS from 'aos';
import "templates/HomePage/Contact.css"

class Contact extends Component {
    constructor(props){
        super(props);

        AOS.init({
            duration: 800,
            easing: 'slide',
            once: false
        });
    }

    componentWillReceiveProps (){
        AOS.refresh();
    }

    render() {
        return (
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-7">
                        <h2 className="section-title mb-3">Message Us</h2>
                        <p className="mb-5">Your questions are a top priority to us. Please note that it may take some time to respond to your inquiry.</p>

                        <form method="post" data-aos="fade">
                            <div className="form-group row">
                                <div className="col-md-6 mb-3 mb-lg-0">
                                    <input type="text" className="form-control" placeholder="First name"/>
                                </div>
                                <div className="col-md-6">
                                    <input type="text" className="form-control" placeholder="Last name"/>
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-12">
                                    <input type="text" className="form-control" placeholder="Subject"/>
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-12">
                                    <input type="email" className="form-control" placeholder="Email"/>
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-md-12">
                                    <textarea className="form-control" id="" cols="30" rows="10" placeholder="Write your message here."></textarea>
                                </div>
                            </div>

                            <div className="form-group row">
                                <div className="col-md-6">
                                    <input type="submit" className="btn btn-primary py-3 px-5 btn-block btn-pill" value="Send Message"/>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
          </div>
        )
    }
}

export default Contact;
