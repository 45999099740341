import React, { Component } from 'react'
import Header from "../Header"
import Home from "./Home"
import Programs from "./Programs"
import Teachers from "./Teachers"
import Contact from "./Contact"
import "templates/HomePage/Homepage.css"

class Homepage extends Component {
    constructor(props){
        super(props);

        this.state = {
            menuVisibility: false
        };
    }

    toggleMenu = () => {
        this.setState((state) => ({
            menuVisibility: !state.menuVisibility
        }))
    };

    render(){

        // Header
        var visible = "hide";
        if(this.state.menuVisibility){
            visible = "show";
        }

        return (
            <div className={visible}>
                <div className="header-section"><Header headerItems={[{id:"home-section", name:"Home", bookmark:true}, {id:"about-section", name:"Gallery", bookmark:true}, {id:"gallery-section", name:"About Me", bookmark:true}, {id:"contact-section", name:"Contact", bookmark:true}]} menuVisibility={this.toggleMenu}/></div>
                <div className="intro-section" id="home-section"><Home/></div>
                <div className="site-section" id="gallery-section"><Teachers/></div>
                <div className="site-section" id="about-section"><Programs/></div>
                <div className="site-section bg-dark" id="contact-section"><Contact/></div>
            </div>
        )
    }
}

export default Homepage;
